@use "@angular/material" as mat;
@include mat.core();
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

$blue-app-primary: mat.define-palette(mat.$blue-palette, 600);
$blue-app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$blue-app-warn: mat.define-palette(mat.$pink-palette);

// Create the theme object (a Sass map containing all of the palettes).
$blue-app-theme: mat.define-light-theme((
  color: (
    primary: $blue-app-primary,
    accent: $blue-app-accent,
    warn: $blue-app-warn,
  ),
));

$themecolor: #1e88e5;
$themecolor-alt: #1e88e5;
$white: #fff;