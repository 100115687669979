/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */

@media (min-width: 1650px) {
    .main-container {
        max-width: 1400px !important;
    }

    .container {
        max-width: 1400px !important;
    }
}

/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */

@media (max-width: 1650px) {
    .main-container {
        max-width: 1800px !important;
    }

    .container {
        max-width: 1800px !important;
    }
}
/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */

@media (max-width: 1200px) {
    .main-container {
        max-width: 900px !important;
    }

    .container {
        max-width: 900px !important;
    }
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */

@media (max-width: 767px) {
    .main-container {
        margin: 0px !important;
        padding: 0px !important;
    }
}
