/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

@use "@angular/material" as mat;
@import "@angular/material/theming";
@include mat.core();

/*Material Theme Colors*/

$red-app-primary: mat.define-palette(mat.$red-palette);
$red-app-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
$red-app-warn: mat.define-palette(mat.$pink-palette);
// Create the theme object (a Sass map containing all of the palettes).
$red-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $red-app-primary,
      accent: $red-app-accent,
      warn: $red-app-warn,
    ),
  )
);

$themecolor: #f44336;
$themecolor-alt: #e53935;
$white: #fff;