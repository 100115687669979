/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@use "@angular/material" as mat;
// Variables
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

$bodyfont: "Montserrat", sans-serif;
$headingfont: "Montserrat", sans-serif;

@import "@angular/material/theming";

/*Material Theme Colors*/

$primary: mat.define-palette(mat.$blue-palette, 600);
$accent: mat.define-palette(mat.$indigo-palette, 500);
$warn: mat.define-palette(mat.$pink-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme
@include mat.all-legacy-component-themes($theme);

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #40b7a2;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
