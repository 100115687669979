@use "@angular/material" as mat;
@use '@ng-matero/extensions' as mtx;
@import "node_modules/@marcreichel/angular-carousel/css/main.css";

/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import "variable";
@import "app";
@import "header";
@import "responsive";

@font-face {
    font-family: "kenteken";
    font-style: normal;
    font-weight: bold;
    src: url("../fonts/kenteken.ttf");
}

// Red Theme
.danger {
	@import "colors/red";
	//@include mat.all-legacy-component-themes($red-app-theme);
}

// Blue Theme
.blue {
	@import "colors/blue";
	//@include mat.all-legacy-component-themes($blue-app-theme);
}

// Green Theme
.green {
	@import "colors/green";
	//@include mat.all-legacy-component-themes($green-app-theme);
}

// Dark Theme
.dark {
	@import "colors/dark";
	//@include mat.all-legacy-component-themes($dark-theme);
}

$custom-typography: mat.define-legacy-typography-config(
	$font-family: "Montserrat",
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

.registration {
	.mat-form-field-label {
		transform: translate(0px, -21px) !important;
	}
}

.carousel__bullet {
	width: 2rem;
	height: 0.2rem;
	background: #54748B;
	opacity: 0.25;
 }
 
 .carousel__bullet:hover {
	opacity: 0.6;
 }
 
 .carousel__bullet.carousel__bullet--active {
	opacity: 1;
	background: #35BDA6;
 }