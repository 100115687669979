/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
 Topbar
*******************/
mat-toolbar {
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);

    &.topbar {
        padding: 0px 15px;
    }
    .navbar-header {
        text-align: center;
        .navbar-brand {
            display: flex;
            line-height: 18px;
            margin-left: 40px;
            margin-top: 5px;
            align-items: center;
            .dark-logo {
                display: none;
            }
        }
    }

    .app-search {
        display: none;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 1;
        position: absolute;
        .form-control {
            line-height: 42px;
        }
        .cl-srh-btn {
            position: absolute;
            top: 12px;
            right: 15px;
            font-size: 15px;
            color: $bodytext;
            cursor: pointer;
        }
    }
    .profile-pic {
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }
}
/*******************
 Topbar
*******************/
.topbar {
    position: relative;
    /* Make sure the toolbar will stay on top of the content as it scrolls past. */
    z-index: 2;
}